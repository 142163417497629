<template>
  <div>
    <div id="main" class="site-main">

<div id="main-content" class="main-content">

<div id="page-title" class="page-title-block page-title-style-1 " style=""><div class="container"><div class="page-title-title"><h1 style="">  Наши мероприятия</h1></div></div></div>
	<div class="block-content">
		<div class="container">
			<div class="panel row panel-sidebar-position-right with-sidebar">

<newsblockUniversal :category="categoryevents" count=8 :page="showpage"/>


				<div class="sidebar col-lg-3 col-md-3 col-sm-12" role="complementary"><div class="widget-area">
<searchbar />
<calendar />

<rubricator position="events" />
<tagscloud/>

</div>
</div><!-- .sidebar -->			</div>
		</div><!-- .container -->
	</div><!-- .block-content -->
</div><!-- #main-content -->

<!-- Your Google Analytics Plugin is missing the tracking ID -->

		</div>
  </div>
</template>

<script>
import categories from "@/lib/categories.js"
import tagscloud from "@/components/tagscloud.vue"
import rubricator from "@/components/rubricator.vue"
import newsblockUniversal from "@/components/news/newsblock-universal.vue"
import searchbar from "@/components/searchbar.vue"
import calendar from "@/components/calendar.vue"
import {rollup} from "@/lib/utils.js"
export default {
name : 'News_events',
components : {tagscloud, rubricator,newsblockUniversal,searchbar, calendar},
data() {

  let showpage = 1
    console.log(this.$route.params)

    if(this.$route.params.page)
    {
      showpage = this.$route.params.page
    }

  return {
      categoryevents : categories.events,
      showpage : showpage
       }
},
mounted() {
rollup()

},
watch : {

$route() {

rollup()

}


}


}


</script>
